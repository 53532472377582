import SlimSelect from 'slim-select'

const findUses = () => {
  document.querySelectorAll('.js-slim-select').forEach((el) => {
    el.classList.remove('js-slim-select');
    new SlimSelect({select: el.querySelector('select')});
  });
};

document.addEventListener('turbolinks:load', findUses);
document.addEventListener('modal:opened', findUses);
document.addEventListener('ajax:success', findUses);
