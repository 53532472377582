const pickupWindowDaySelector = (element) => {
  element.querySelectorAll('li').forEach((li) => {
    li.addEventListener('click', (e) => {
      e.preventDefault();
      element.querySelectorAll('li.active').forEach((li) => { li.classList.remove('active'); });
      li.classList.add('active');

      // swap the day out
      const selectedDay = Number(li.querySelector('[data-pickup-window-day]').getAttribute('data-pickup-window-day'));
      document.querySelectorAll('.js-pickup-window-selector-display').forEach((dayElement) => {
        const thisDay = Number(dayElement.getAttribute('data-pickup-window-day-sel'));
        const isSelected = selectedDay === thisDay;
        dayElement.classList.toggle('d-none', !isSelected);
      });
    });
  });
};

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.js-pickup-window-day-selector').forEach((element) => {
    pickupWindowDaySelector(element);
  });
});
