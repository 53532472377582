document.addEventListener('turbolinks:load', () => {
  const hookup = (element) => {
    const hidden = element.querySelector('input[type="text"]');
    const value = hidden.value;

    const currentRadio = element.querySelector(`input[type="radio"][value="${value}"]`);
    if (currentRadio) {
      currentRadio.checked = true;
    } else {
      const customRadio = element.querySelector(`input[type="radio"][value="custom"]`);
      customRadio.checked = true;
      hidden.classList.remove('d-none');
    }

    element.addEventListener('change', (event) => {
      const radio = event.target;

      // Look for a radio with this value, and if there is one we can hide
      const existingRadio = element.querySelector(`input[type="radio"][value="${radio.value}"]`);

      if (existingRadio && radio.value !== 'custom') {
        existingRadio.checked = true;
        hidden.value = radio.value;
        hidden.classList.add('d-none');
      } else {
        hidden.classList.remove('d-none');
      }
    });
  };

  document.querySelectorAll('.js-radio-proxy').forEach((element) => {
    hookup(element);
  });
});
