const wire = (element) => {
  const display = element.querySelector('.js-colin-select-display');
  const input = element.querySelector('input');
  const lessButton = element.querySelector('.js-colin-select-less');
  const moreButton = element.querySelector('.js-colin-select-more');
  const disabledClass = element.getAttribute('data-disabled-class') || 'disabled';
  const priceLabel = element.querySelector('.js-colin-select-price');

  // Get the unit price so we can update the total accordingly
  const unitPrice = parseInt(element.getAttribute('data-unit-price-cents'), 10);

  // Read max and make an int if it exists
  let max = element.getAttribute('data-max');
  if (max) max = parseInt(max, 10);

  // Parse the initial value
  let num = parseInt(input.value, 10);

  // Adjust the count by the set amount, obeying rules
  const adjustCount = (by) => {
    num += by;

    if (num < 0) num = 0;
    if (max && num > max) num = max;

    lessButton.classList.toggle(disabledClass, num === 0);
    moreButton.classList.toggle(disabledClass, num === max);

    display.innerText = num;
    input.value = num;

    priceLabel.innerText = `$${((num * unitPrice / 100.0)).toFixed(2)}`;
  };

  // Put initial state
  adjustCount(0);

  // Wire up less & more buttons
  lessButton.addEventListener('click', () => adjustCount(-1));
  moreButton.addEventListener('click', () => adjustCount(1));
};

const findUses = () => {
  document.querySelectorAll('.js-colin-select').forEach(e => wire(e));
};

document.addEventListener('turbolinks:load', findUses);
document.addEventListener('modal:opened', findUses);
