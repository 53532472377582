document.addEventListener('turbolinks:load', () => {
  const wrapper = document.querySelector('.js-age-gate');
  if (!wrapper) return;

  const button = wrapper.querySelector('.js-verify-age-gate');
  if (!button) return;

  const toggleButton = (b) => {
    if (b) {
      button.classList.remove('btn-disabled');
      button.disabled = undefined;
    } else {
      button.classList.add('btn-disabled');
      button.disabled = true
    }
  };

  wrapper.addEventListener('change', () => {
    const year = Number(wrapper.querySelector('select[name="year"]').value);
    const month = Number(wrapper.querySelector('select[name="month"]').value);
    const day = Number(wrapper.querySelector('select[name="day"]').value);

    let legal = false;

    if (year && month && day) {
      const ageDifMs = Date.now() - new Date(year, month, day).getTime();
      const ageDate = new Date(ageDifMs); // miliseconds from epoch
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      legal = age >= 21;
    }

    toggleButton(legal);
  });

  button.addEventListener('click', (e) => {
    e.preventDefault();

    if (button.classList.contains('btn-disabled')) return;

    wrapper.classList.add('d-none');
    fetch('/dismiss-age-gate', {method: 'GET', headers: {'X-Requested-With': 'XMLHttpRequest'}});
  });
});
