(() => {
  const buildApplePaymentRequest = (form, payments) => {
    return payments.paymentRequest({
      requestShippingAddress: false,
      requestBillingInfo: false,
      currencyCode: "USD",
      countryCode: "US",
      total: {
        label: "Beer Broadcast",
        amount: form.querySelector('meta[name="js-payment-wallet-amount"]').content,
        pending: false
      }
    });
  };

  const handlePaymentMethodSubmission = async (paymentMethod, nonceField, form) => {
    if (form.locked) { return; } // detected double click

    // global lock the form, and disable the buttons
    form.locked = true;
    const buttons = form.querySelectorAll('button');
    buttons.forEach((b) => b.disabled = true);

    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      nonceField.value = tokenResult.token;
      nonceField.closest('form').submit();
    } else {
      console.log('tokenization failed', tokenResult);
      // let them try again
      buttons.forEach((b) => b.disabled = false);
      form.locked = false;
    }
  };

  const supportCardPayments = async (form, payments) => {
    const cardButton = form.querySelector('.js-payment-card-button');
    const cardContainer = form.querySelector('.js-payment-card-container');
    if (!cardButton || !cardContainer) return;

    let card;
    try {
      card = await payments.card();
      await card.attach(cardContainer);
    } catch (e) {
      console.error('initializing card payments failed', e);
      return;
    }

    cardButton.addEventListener('click', async (event) => {
      event.preventDefault();
      let nonceField = form.querySelector('input.js-payment-nonce');
      await handlePaymentMethodSubmission(card, nonceField, form);
    });
  };

  // Support apple pay if the button is present on the page
  const supportApplePayPayments = async (form, payments) => {
    const applePayButton = form.querySelector('.js-payment-apple-pay-button');
    if (!applePayButton) return;

    let applePay;
    try {
      const paymentRequest = buildApplePaymentRequest(form, payments);
      applePay = await payments.applePay(paymentRequest);
    } catch (e) {
      console.log('apple pay not supported on this device', e);
      return;
    }

    applePayButton.classList.remove('d-none');
    applePayButton.addEventListener('click', async function (event) {
      event.preventDefault();
      let nonceField = form.querySelector('input.js-payment-nonce');
      await handlePaymentMethodSubmission(applePay, nonceField, form);
    });
  };

  const supportGiftCardPayments = async (form, payments) => {
    const giftCardContainer = form.querySelector('.js-payment-gift-card-container');
    const giftCardButton = form.querySelector('.js-payment-gift-card-button');
    if (!giftCardContainer || !giftCardButton) return;

    let giftCard;
    try {
      giftCard = await payments.giftCard();
      await giftCard.attach(giftCardContainer);
    } catch (e) {
      console.error('initializing gift card failed', e);
      return;
    }

    giftCardButton.addEventListener('click', async function (event) {
      event.preventDefault();
      let nonceField = form.querySelector('input.js-payment-gift-card-nonce');
      await handlePaymentMethodSubmission(giftCard, nonceField, form);
    });
  };

  const enhancePaymentForm = async (form) => {
    const applicationId = document.querySelector('meta[name="sq-app-id"]').content;
    let locationId = document.querySelector('meta[name="sq-location-id"]').content;
    const payments = window.Square.payments(applicationId, locationId);

    if (document.querySelector('.js-payment-form-ignore-location')) {
      locationId = undefined;
    }

    await supportCardPayments(form, payments);
    await supportApplePayPayments(form, payments);
    await supportGiftCardPayments(form, payments);
  };

  // Hook into page load, if a payment form is present, enhance it
  document.addEventListener('turbolinks:load', async () => {
    const form = document.querySelector('.js-payment-form');
    if (!form) return;

    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    enhancePaymentForm(form);
  });
})();
