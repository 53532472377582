document.addEventListener('turbolinks:load', () => {

  document.querySelectorAll('.js-copy').forEach((element) => {
    const control = element.querySelector('.js-copy-control');
    const textDiv = element.querySelector('.js-copy-text');
    const button = control.querySelector('a');

    button.addEventListener('click', (event) => {
      textDiv.select();

      const success = document.execCommand('copy');
      if (success) {
        control.innerText = 'Copied!';
      }

      event.preventDefault();
    });
  });

});
