/* A quick library that allows adding an acknowledge button and hiding some
 * other element until it's clicked */
document.addEventListener('turbolinks:load', () => {
  let initialize = (button) => {
    let wrapper = button.closest('.js-acknowledge-container');
    let targetClass = button.dataset.acknowledgeTarget;
    let target = document.querySelector('.' + targetClass);

    // hide the thing
    let original = target.style.display;
    target.style.display = 'none';

    // allow clicking to bring it back
    button.addEventListener('click', (e) => {
      e.preventDefault();
      target.style.display = original;
      wrapper.style.display = 'none';
    });
  };

  document.querySelectorAll('[data-acknowledge-target]').forEach((button) => {
    initialize(button);
  });
});
