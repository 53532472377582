document.addEventListener('modal:opened', () => {
  const button = document.querySelector('.js-checkin-button');
  const form = document.querySelector('.js-checkin');
  if (!button || !form) return;

  if (!navigator.geolocation) {
    const error = document.querySelector('.js-checkin-error');
    const message = error.querySelector('.js-checkin-error-message');
    message.innerText = 'Your browser does not support location';
    error.classList.remove('d-none');
    return;
  }

  button.classList.remove('d-none');

  button.addEventListener('click', () => {
    const spinner = document.querySelector('.js-checkin-spinner');
    spinner.classList.remove('d-none');
    button.classList.add('d-none');

    navigator.geolocation.getCurrentPosition(
      (location) => {
        form.querySelector('[name="latitude"]').value = location.coords.latitude;
        form.querySelector('[name="longitude"]').value = location.coords.longitude;
        form.submit();
      },

      (err) => {
        const error = document.querySelector('.js-checkin-error');
        const message = error.querySelector('.js-checkin-error-message');
        spinner.classList.add('d-none');
        message.innerText = err.message;
        error.classList.remove('d-none');
      }
    );
  });
});
