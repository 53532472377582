document.addEventListener('turbolinks:load', function() {

  const overlay = document.querySelector('.Overlay');
  if (!overlay) return;

  const body = overlay.querySelector('.Overlay--body');
  const header_p = overlay.querySelector('.Overlay--header p');

  overlay.addEventListener('ajax:success', (xhr) => {
    body.innerHTML = '';

    if (xhr.detail[0] && typeof xhr.detail[0] !== 'string') {
      body.appendChild(xhr.detail[0].querySelector('body'));
    } else {
      overlay.classList.add('d-none'); // hide
    }
  });

  const openModal = (text) => {
    body.innerHTML = text;

    const meta = body.querySelector('meta[name="modal-title"]');
    header_p.innerText = meta ? meta.content : '';

    overlay.classList.remove('d-none');

    let event = new CustomEvent('modal:opened');
    document.dispatchEvent(event);
  };

  const addClickListener = (element) => {
    element.addEventListener('click', async (event) => {
      event.preventDefault();
      event.stopPropagation();

      const dropdown = event.target.closest('.dropdown');
      if (dropdown) { dropdown.removeAttribute('open'); }

      let headers = {};
      headers['X-Requested-With'] = 'XMLHttpRequest';

      const response = await fetch(element.href, {headers: headers});
      const text = await response.text();
      openModal(text);
    });
  };

  // Load on all
  document.querySelectorAll('[data-modal]').forEach((element) => {
    addClickListener(element);
  });

  overlay.addEventListener('click', async (event) => {
    if (event.target.closest('.js-modal-close')) {
      overlay.classList.add('d-none');

      event.preventDefault();
      event.stopPropagation();
    }

    if (event.target.closest('.js-modal-link')) {
      console.log(event.target.href);
      // close modal
      overlay.classList.add('d-none');
      // prevent nav
      event.preventDefault();
      event.stopPropagation();
      // click a link to re-open
      let headers = {};
      headers['X-Requested-With'] = 'XMLHttpRequest';
      const response = await fetch(event.target.href, {headers: headers});
      const text = await response.text();
      openModal(text);
    }
  });

});
