// Get zip code

document.addEventListener('turbolinks:load', () => {
  const overlay = document.querySelector('.js-zip-gate');
  if (!overlay) return;

  const close = overlay.querySelector('.js-zip-gate-close');
  const form = overlay.querySelector('form');

  close.addEventListener('click', () => {
    overlay.classList.add('d-none');
  });

  form.addEventListener('submit', () => {
    overlay.classList.add('d-none');
  });
});
