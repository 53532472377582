const loadHeatMap = async (element) => {
  const url = element.getAttribute('data-url');

  const response = await fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}});
  const content = await response.text();
  const data = JSON.parse(content);

  const center = new google.maps.LatLng(data.center[0], data.center[1]);

  const map = new google.maps.Map(element, {
    center: center,
    zoom: 10,
    mapTypeId: 'hybrid'
  });

  map.setOptions({ minZoom: 5, maxZoom: 12 });

  new google.maps.Marker({ position: center, map });

  const heatmap = new google.maps.visualization.HeatmapLayer({
    data: data.points.map((arr) => {
      return {
        location: new google.maps.LatLng(arr[0], arr[1]),
        weight: arr[2]
      };
    }),
    radius: 50
  });

  heatmap.setMap(map);
};

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.js-flexible-heatmap').forEach((element) => {
    loadHeatMap(element);
  });
});
