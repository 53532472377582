require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('./colin-select')
require('./auto-open')
require('./modal')
require('./copy')
require('./radio-proxy')
require('./square-payments')
require('./countdown')
require('./heatmap')
require('./age-gate')
require('./zip_gate')
require('./pickup-window-day-selector');
require('./slim-select')
require('./checkin')
require('./acknowledge')

require("feather-icons")

require("flatpickr")

import feather from "feather-icons";
import flatpickr from "flatpickr";
import Sortable from "sortablejs";

/* Hook up feathericons */

document.addEventListener('turbolinks:load', () => { feather.replace(); });
document.addEventListener('modal:opened', () => { feather.replace(); });

/* Hook up flatpickr */

const loadFlat = () => {
  document.querySelectorAll('input[type=datetime]').forEach((element) => {
    flatpickr(element, {enableTime: true, altInput: true, altFormat: 'F j, Y h:i K'});
  });

  document.querySelectorAll('input[type=date]').forEach((element) => {
    flatpickr(element, {enableTime: false, altInput: true, altFormat: "F j, Y"});
  });

  document.querySelectorAll('input[type=time]').forEach((element) => {
    flatpickr(element, {enableTime: true, noCalendar: true, altInput: true, altFormat: "h:i K", dateFormat: 'H:i'});
  });
};

document.addEventListener('turbolinks:load', () => loadFlat());
document.addEventListener('modal:opened', () => loadFlat());

/* payment form refreshes total on changes */

document.addEventListener('change', async (event) => {
  const form = event.target.closest('form.js-payment-form');
  if (!form) return;

  const breakdownUrl = form.getAttribute('data-breakdown-url');

	// Send a request to the server, and replace contents when we get it back
  let formData = new FormData(form);
  formData.delete('_method');
  const response = await fetch(breakdownUrl, {
    method: 'post',
    body: formData,
    headers: {
      'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
    }
  })

  const content = await response.text();

  form.querySelector('.js-payment-summary').innerHTML = content;
});

/* utility for toggling a section with a checkbox */

const processCheck = (check) => {
  const selector = check.closest('[data-js-toggle-target]').getAttribute('data-js-toggle-target');

  document.querySelectorAll(selector).forEach((element) => {
    element.classList.toggle('d-none', !!check.checked);
  });
};

const addClickListener = (check) => {
  check.addEventListener('click', () => processCheck(check));
};

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-js-toggle-target] input[type="checkbox"]').forEach((element) => {
    processCheck(element);
    addClickListener(element);
  });
});

// clicking an x inside of a banner will hide it

document.addEventListener('mouseup', (event) => {
  const button = event.target.closest('.js-banner-dismiss');
  if (!button) return;

  const container = button.closest('.js-banner-dismiss-target');
  container.classList.add('d-none');
});

// quick toggle

document.addEventListener('turbolinks:load', () => {
  const setState = (element, enabled) => {
    element.classList.toggle('bg-gray', !enabled);
    element.classList.toggle('bg-green', enabled);
    element.classList.toggle('flex-justify-start', !enabled);
    element.classList.toggle('flex-justify-end', enabled);
  };

  const toggle = async (element) => {
    const url = element.getAttribute('data-quick-toggle');

    const response = await fetch(url, {
      method: 'post',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'X-Requested-With': 'XMLHttpRequest'
      }
    })

    const content = await response.text();
    const data = JSON.parse(content);

    setState(element, data.enabled);

    if (element.hasAttribute('data-reload')) {
      Turbolinks.visit(window.location);
    }
  };

  const addClickListener = (element) => {
    element.addEventListener('click', () => {
      toggle(element);
    });
  };

  const readInitialState = (element) => {
    const enabled = element.getAttribute('data-quick-toggle-enabled') === 'true';

    setState(element, enabled);
  };

  document.querySelectorAll('[data-quick-toggle]').forEach((element) => {
    addClickListener(element);
    readInitialState(element);
  });
});

// sortable lists

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-quick-sortable]').forEach((element) => {
    new Sortable(element, {
      onEnd: async () => {
        const url = element.getAttribute('data-quick-sortable');

        const ids = [];
        element.querySelectorAll('[data-quick-sortable-id]').forEach((el) => {
          ids.push(el.getAttribute('data-quick-sortable-id'));
        });

        const body = new FormData();
        body.append('ids', ids.join(','));

        fetch(url, {
          method: 'post',
          body: body,
          headers: {
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
          }
        })
      },
      delay: 200
    });
  });
});

// allow remote to reload the page

document.addEventListener('ajax:success', (event) => {
  if (event.target.hasAttribute('data-reload')) {
    Turbolinks.visit(window.location);
  }
});

// hide/remove driver tip area

document.addEventListener('turbolinks:load', () => {
  const controls = document.querySelectorAll('input[name="cart[delivery]"]');
  if (!controls.length) return;

  const handle = () => {
    // The selected checkbox, or the hidden field if we're showing that instead
    const control = document.querySelector('input[name="cart[delivery]"]:checked,input[type="hidden"][name="cart[delivery]"]');

    if (control.value === 'true') {
      document.querySelectorAll('.js-delivery-only').forEach(e => e.classList.remove('d-none'));
      document.querySelectorAll('.js-pickup-only').forEach(e => e.classList.add('d-none'));
    } else {
      document.querySelectorAll('.js-delivery-only').forEach(e => e.classList.add('d-none'));
      document.querySelectorAll('.js-pickup-only').forEach(e => e.classList.remove('d-none'));
    }
  };

  controls.forEach((control) => {
    control.addEventListener('change', () => {
      handle();
    });
  });

  handle();
});

// flop-on-success

document.addEventListener('ajax:success', () => {
  const parent = event.target.closest('[data-flop-on-success]');

  if (parent) {
    parent.querySelectorAll('a').forEach((link) => {
      link.classList.toggle('d-none');
    });
  }
});

// toggle-target

document.addEventListener('click', (e) => {
  const parent = event.target.closest('[data-toggle-target]');
  if (!parent) return;

  const target = parent.getAttribute('data-toggle-target');
  document.querySelector(target).classList.toggle('d-block');

  e.preventDefault();
});

// js-disable-on-click

const disableFormDoubleClicks = () => {
  document.querySelectorAll('form').forEach((form) => {
    if (form.doubleClicksDisabled) {
      return;
    }

    form.doubleClicksDisabled = true;

    form.addEventListener('submit', (e) => {
      form.querySelectorAll('[type="submit"]').forEach((button) => {
        button.classList.add('btn-disabled');
        button.disabled = true;
      });
    });
  });
};

document.addEventListener('turbolinks:load', () => disableFormDoubleClicks());
document.addEventListener('modal:opened', () => disableFormDoubleClicks());

require("trix")
require("@rails/actiontext")
