let intervalsToClearOnLoad = [];

document.addEventListener('turbolinks:load', () => {
  const pad = (num, size) => { return ('000' + num).slice(size * -1); };

  intervalsToClearOnLoad.forEach((interval) => { clearInterval(interval); });
  intervalsToClearOnLoad = [];

  document.querySelectorAll('.js-countdown').forEach((element) => {
    const secondsLeft = element.getAttribute('data-expiration');

    const initTime = new Date();

    const trigger = () => {
      const nowTime = new Date();
      const difference = parseInt((nowTime.getTime() - initTime.getTime()) / 1000, 10);
      const remaining = secondsLeft - difference;

      if (remaining > 0) {
        const minutes = Math.floor(remaining / 60) % 60;
        const seconds = Math.floor(remaining - minutes * 60);
        element.innerText = pad(minutes, 2) + ':' + pad(seconds, 2);
      } else {
        clearInterval(interval);
        element.innerText = 'Expired';
        window.location.reload();
      }
    };

    trigger();
    const interval = setInterval(() => trigger(), 1000);
    intervalsToClearOnLoad.push(interval);
  });
});
